import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import LandingPage from './pages/LandingPage';
import PassSport from './pages/PassSport';
import PlayLocal from './pages/PlayLocal';
import SkillSharer from './pages/SkillSharer';
import SportyClub from './pages/SportyClub';

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/play-local" element={<PlayLocal />} />
        <Route path="/sporty-club" element={<SportyClub />} />
        <Route path="/skill-sharer" element={<SkillSharer />} />
        <Route path="/pass-sport" element={<PassSport />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
