import React, { useEffect, useRef, useState } from 'react';
import { useIsInViewport } from '../../../hooks/IsInViewport';
import './_heroSection.scss';

//Hero images

import HeroImage from '../../../assets/images/fullw/strechedHero.jpg';
import Apple from '../../../assets/images/apple.svg';
import Android from '../../../assets/images/android.svg';
import Toast from '../../../components/Toast';
import ContactForm from '../ContactForm';

export const HeroSection = (props: {
  bgOpacity: number;
  bg: string;
  bw: boolean;
}) => {
  const { bg, bgOpacity, bw } = props;
  const [animation, setAnimation] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const sectionRef = useRef(null);

  const [type, setType] = useState('');

  const [isContactFormVisible, setIsContactFormVisible] = useState(false);

  const handleOpenContactForm = (typeData: string) => {
    setIsContactFormVisible(true);
    setType(typeData);
  };

  const handleCloseContactForm = () => {
    setIsContactFormVisible(false);
  };

  const isInViewport = useIsInViewport(sectionRef);

  useEffect(() => {
    if (!animation && isInViewport) {
      setAnimation(true);
    }
  }, [animation, isInViewport]);

  return (
    <div ref={sectionRef} className={`hero fullW ${animation && 'fadeIn'}`}>
      <img
        src={HeroImage}
        alt="hero"
        style={{ filter: bw ? 'grayscale(100%)' : 'none' }}
      />
      <div
        className="hero-overlay"
        style={{ background: bg, opacity: bgOpacity }}
      />
      <div className="hero-content">
        <div className="section-title">
          <h3>
          <span className='title-green'>SPORTI</span> - the new app to find,
          </h3>
          <h3>
            compare and <span className='title-green'>book local sports</span>
          </h3>
        </div>
        <div className="gap"></div>
        <div className="content-row">
          <div className='short-content'>
            <div className="short-message">
              <h4>
              <div className='shortmsg-pre'>Right now we're onboarding <br/>local Providers (Sellers)</div>
              To <span className='title-green'>book local sports </span> <span className="shortmsg-link" onClick={() => handleOpenContactForm('user')}>click here</span>
              &nbsp; <br />to get notified in November
              </h4>
            </div>
          </div>
          {/* <div className="appStore">
          <button onClick={() => handleOpenContactForm('provider')}>
            Give me access
          </button>
        </div> */}
          <div className='short-content'>
          <div className="short-message">
            <h4>
              Providers download the app here <br />
              <span>to sell your sports for <span  className='title-green'>Free!</span></span>
            </h4>

            <div className="appStore">
              {/* <button onClick={() => handleOpenContactForm('user')}>
            GET SPORTI
          </button> */}
              <div className="appStoreImage">
                <a
                  href="https://apps.apple.com/in/app/sporti-app/id1628232729?utm_source=sporti_website"
                  title="Check Sporforya IOS app">
                  <img src={Apple} alt="Apple" className="app-image" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sporforya&utm_source=sporti_website"
                  title="Check Sporforya Android app">
                  <img src={Android} alt="Apple" className="app-image" />
                </a>
              </div>
            </div>
          </div>
          </div>
        </div>
        {/* <div className="appStore">
          <button onClick={() => handleOpenContactForm('user')}>
            Send to me
          </button>

          <div className="appStoreImage">
            <a
              href="https://sporforya.page.link/KPo2"
              title="Check Sporforya IOS app">
              <img src={Apple} alt="Apple" className="app-image" />
            </a>
            <a
              href="https://sporforya.page.link/KPo2"
              title="Check Sporforya Android app">
              <img src={Android} alt="Apple" className="app-image" />
            </a>
          </div>
        </div> */}

        {isContactFormVisible && (
          <ContactForm onClose={handleCloseContactForm} type={type} setShowToast={setShowToast} />
        )}
      </div>
      <Toast
        testId="request-form-toast"
        open={showToast}
        onClose={() => setShowToast(false)}
        title="App is on it's way!"
        message="Thanks, we got you on the list and will send you the App ASAP"
      />
    </div>
  );
};

export default React.memo(HeroSection);
